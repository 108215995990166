import React from 'react'

interface Props {
    checked: boolean
    onChange: (value: any) => void
}

export const Checkbox: React.FC<Props> = ({ checked, onChange }) => {
    return (
        // <input
        //     type='checkbox'
        //     checked={checked}
        //     onChange={(e: any) => onChange(e.target.checked)}
        //     className='checkbox'
        // />
        <label className="container-checkbox">
            <input
                type='checkbox'
                checked={checked}
                onChange={(e: any) => onChange(e.target.checked)} />
            <span className="checkmark"></span>
        </label>
    );
}