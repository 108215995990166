import React from "react";

import { ActionType } from "../../types/Table";
// import { GuaranteeBodyType } from '../../types/Guarantee';
import { AdvancedTable, IAdvancedTableColumn, IAdvancedTableExportCsvColumn, ButtonType, ButtonState, ButtonProps } from "@mit/hui";
import { useAppContext, useFormContext } from "../../context/";
import { formatName, getDateString } from "../../common/Util";

const columns: IAdvancedTableColumn[] = [
    {
        id: "student/name",
        name: "Student",
    },
    {
        id: "sponsor/name",
        name: "Sponsor ",
    },
    {
        id: "department/name",
        name: "Department",
    },
    {
        id: "term/name",
        name: "Term",
    },
    // {
    //     id: 'location/name',
    //     name: 'Location'
    // },
    // {
    //     id: 'payment/name',
    //     name: 'Payment'
    // },
    {
        id: "funding/name",
        name: "Funding Source",
        formatter: (item: any) => {
            let value = item.funding.id;
            switch (value) {
                case 1:
                    return "ELO Guarantee Funding";
                case 2:
                    return "Another MIT source";
            }
            return "";
        },
    },
    {
        id: "max_awarded_amount",
        name: "Max Awarded Amount",
        formatter: (item: any) => {
            return `$${item.max_awarded_amount}`;
        },
    },
];

const csvExportCol: IAdvancedTableExportCsvColumn[] = [
    {
        id: "student/id",
        name: "Stduent MIT ID",
    },
    {
        id: "student/name",
        name: "Student Name",
    },
    {
        id: "student/email",
        name: "Student Email",
    },
    {
        id: "year",
        name: "Year",
    },
    {
        id: "class",
        name: "Class",
    },
    {
        id: "course",
        name: "Course",
    },
    {
        id: "double_major",
        name: "Double Major",
    },
    {
        id: "citizen",
        name: "Citizen",
    },
    {
        id: "gender",
        name: "Gender",
    },
    {
        id: "ethnicity",
        name: "Ethnicity",
    },
    {
        id: "first_gen",
        name: "First Gen",
    },
    {
        id: "sponsor/id",
        name: "Supervisor MIT ID",
    },
    {
        id: "sponsor/name",
        name: "Supervisor Name",
    },
    {
        id: "sponsor/email",
        name: "Supervisor Email",
    },
    {
        id: "department/name",
        name: "Department",
    },
    {
        id: "term/name",
        name: "Term",
    },
    {
        id: "location/name",
        name: "StudentLocation",
    },
    {
        id: "payment/name",
        name: "Payment",
    },
    {
        id: "funding/name",
        name: "Source of Funding",
        formatter: (item: any) => {
            let value = item.funding.id;
            switch (value) {
                case 1:
                    return "ELO Guarantee Funding";
                case 2:
                    return "Another MIT source";
            }
            return "";
        },
    },
    {
        id: "program_name",
        name: "ELO Program",
    },
    {
        id: "max_awarded_amount",
        name: "Maximum Amount Awarded to Student",
        formatter: (item: any) => {
            return `$${item.max_awarded_amount}`;
        },
    },
    {
        id: "comments",
        name: "Comments",
    },

    {
        id: "advisor_name",
        name: "Advisor Name",
        formatter: (item: any) => formatName(item.advisor_name),
    },
    {
        id: "advisor_email",
        name: "Advisor Email",
    },
];

let today = new Date();
let dateString = getDateString(today);

const exportOptions = {
    button: {
        type: ButtonType.Primary,
        icon: "download",
        onClick: () => alert("Download Csv"),
        text: "Export CSV",
        state: ButtonState.Enabled,
    },
    columns: csvExportCol,
    items: [],
    fileName: `ELO ${dateString}.csv`,
};

interface Props {
    context: any;
    showButton?: boolean;
}

export const GuaranteeTable: React.FC<Props> = ({ context, showButton }) => {
    const { setGuaranteeForm }: any = useFormContext();
    const { items, isLoading }: any = context;
    const { auth }: any = useAppContext();

    const handleActionButtonClick = (type: ActionType, item: any) => {
        setGuaranteeForm({
            show: true,
            type: type,
            data: item,
        });
    };

    const getActionButtons = (): ButtonProps[] => {
        let actionButtons: ButtonProps[] = [
            {
                text: "View",
                state: ButtonState.Enabled,
                type: ButtonType.Icon,
                padded: false,
                icon: "info-circle",
                onClick: (item: any) => handleActionButtonClick(ActionType.VIEW, item),
            },
        ];

        if (auth.manage) {
            actionButtons = [
                ...actionButtons,
                {
                    text: "Edit",
                    state: ButtonState.Enabled,
                    type: ButtonType.Icon,
                    padded: false,
                    icon: "edit",
                    onClick: (item: any) => handleActionButtonClick(ActionType.EDIT, item),
                },
                {
                    text: "Delete",
                    state: ButtonState.Enabled,
                    type: ButtonType.Icon,
                    padded: false,
                    icon: "times-circle",
                    onClick: (item: any) => handleActionButtonClick(ActionType.DELETE, item),
                },
            ];
        }

        return actionButtons;
    };

    const loading = isLoading.items || isLoading.list;

    if (!items.length && !loading) {
        return (
            <div className="none-container">
                <h2 className="text-center mb-3">No Guarantees</h2>
            </div>
        );
    }
    return (
        <div className="px-2 py-2 guarantee-table">
            {/* <GenericTable
                columnHeaders={columnHeaders}
                items={items}
                showActionColumn={true}
                actionButtons={getActionButtons()}
            /> */}
            <AdvancedTable columns={columns} items={items} isLoading={loading} exportOptions={exportOptions} showExportButton maxRows={500} numberRowsOnScroll={100} actionButtons={getActionButtons()} />
        </div>
    );
};
