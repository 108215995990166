import React, { useEffect } from 'react'

// import AllocationTable from '../AllocationTable';
// import AllocationController from '../../api/controller/AllocationController';
// import TableLoading from '../TableLoading';
import { GuaranteeTable } from '../table/GuaranteeTable';
import GuaranteeController from '../../api/controller/GuaranteeController';



const controller = new GuaranteeController();


// const allocationController = new AllocationController();

interface Props {
    context: any
}

export const Detail: React.FC<Props> = ({ context }) => {
    const { setItems, selectedItem, setIsLoading, refresh }: any = context;


    useEffect(() => {
        if (selectedItem.hasOwnProperty('id')) {
            setIsLoading((state: any) => ({ ...state, items: true }))
            controller.getGuarantees(selectedItem.id)
                .then(data => {
                    setItems(data);
                    setIsLoading((state: any) => ({ ...state, items: false }))
                })
        }

        //eslint-disable-next-line
    }, [selectedItem, refresh])




    return <GuaranteeTable
        context={context}
        showButton
    />


}