import React, { useEffect } from 'react'

import { GuaranteeTable } from '../table/GuaranteeTable';
import StudentController from '../../api/controller/StudentController';

import { useStudentContext } from '../../context/';

import { BasicLayout } from '@mit/hui';
import { useParams } from 'react-router-dom'

const controller = new StudentController();

interface Props {
    placeholder?: any
}

export const Student: React.FC<Props> = ({ placeholder }) => {
    const context = useStudentContext();
    const { setIsLoading, setItems }: any = context;
    const { type } = useParams();

    const getTitle = (type: string) => {
        switch (type) {
            case 'E': return 'Students with Guarantee';
            case 'N': return 'Students without Guarantee';
        }
        return 'Students'
    }

    useEffect(() => {
        setItems([]);
        setIsLoading((state: any) => ({ ...state, items: true }))
        controller.getStudents(type)
            .then(data => {
                console.log(data);
                setItems(data);
                setIsLoading((state: any) => ({ ...state, items: false }))
            })


        //eslint-disable-next-line
    }, [type])




    return <BasicLayout
        startState={true}
        navigation={[]}
        contentTitle={getTitle(type)}
        content={
            type === 'E' ?
                <GuaranteeTable
                    context={context}
                />
                :
                <h1>No Enrolled Students</h1>
        }
    />


}