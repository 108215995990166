import React from 'react'

import GuaranteeController from '../../api/controller/GuaranteeController';
import { Dropdown } from '../input/Dropdown';

import { useLookupsContext } from '../../context/'

import { Label } from '@mit/hui';
import { Button } from 'react-bootstrap';
import { PeopleSearch } from '../input/PeopleSearch';
import { Checkbox } from '../input/Checkbox';



const controller = new GuaranteeController();

interface Props {
    context: any
}

export const FilterMaster: React.FC<Props> = ({ context }) => {

    const { setItems, setIsLoading, filters, setFilters }: any = context;
    const { departments, terms, funding, paymentTypes }: any = useLookupsContext();


    const onChangeFilter = (item: any, key: string) => {
        setFilters((state: any) => ({ ...state, [key]: item }));
    }

    const clearFilters = () => {
        setFilters({});
    }

    const handleSearch = () => {
        let filterStrings: string[] = [];

        Object.keys(filters).forEach((key: any) => {
            if (filters[key]) {
                let filterString = '';

                if (Array.isArray(filters[key])) {
                    filterString = `${key}=${filters[key].map((item: any) => encodeURIComponent(item.id)).join(',')}`;
                } else if (typeof filters[key] === 'boolean') {
                    filterString = `${key}=${filters[key] ? 1 : 0}`;
                } else {
                    filterString = `${key}=${encodeURIComponent(filters[key])}`;
                }

                if ((key === 'sponsor_id' || key === 'mit_id') && filters[key]) {
                    filterString = `${key}=${encodeURIComponent(filters[key].id)}`
                }
                filterStrings.push(filterString);
            }
        })

        let queryString = filterStrings.length > 0 ? `?${filterStrings.join('&')}` : '';

        console.error(queryString);
        setIsLoading((state: any) => ({ ...state, items: true }))
        controller.getFilterGuarantees(queryString)
            .then(data => {
                setIsLoading((state: any) => ({ ...state, items: false }));
                setItems(data);
            })
            .catch(err => {
                console.error(err);
            })

    }

    return (
        // <FilteredList
        //     isLoading={isLoading.list}
        //     selectable={true}
        //     compact={true}
        //     searchOptions={searchOptions}
        //     items={items}
        //     height={60}
        // />
        <div className='mx-3 my-3 d-flex flex-column'>
            <h5 >Filters</h5>
            <div className={'mt-3'}>
                <Label
                    text={'Department'}
                />
                <Dropdown
                    options={departments}
                    placeholder='Select departments'
                    value={filters.dlc_key}
                    onChange={(item: any) => onChangeFilter(item, 'dlc_key')}
                    isMulti
                />
            </div>
            <div className={'mt-3'}>
                <Label
                    text={'Term'}
                />
                <Dropdown
                    options={terms}
                    placeholder='Select terms'
                    value={filters.term_id}
                    onChange={(item: any) => onChangeFilter(item, 'term_id')}
                    isMulti
                />
            </div>
            <div className={'mt-3'}>
                <Label
                    text={'Funding Source'}
                />
                <Dropdown
                    options={funding}
                    placeholder='Select funding'
                    value={filters.funding_source_id}
                    onChange={(item: any) => onChangeFilter(item, 'funding_source_id')}
                    isMulti
                />
            </div>
            <div className={'mt-3'}>
                <Label
                    text={'Payment Mode'}
                />
                <Dropdown
                    options={paymentTypes}
                    placeholder='Select payment mode'
                    value={filters.payment_type_id}
                    onChange={(item: any) => onChangeFilter(item, 'payment_type_id')}
                    isMulti
                />
            </div>
            <div className={'mt-3'}>
                <Label
                    text={'Sponsor'}
                />
                <PeopleSearch
                    value={filters.sponsor_id}
                    onChange={(item: any) => onChangeFilter(item, 'sponsor_id')} />
            </div>
            <div className={'mt-3'}>
                <Label
                    text={'Student'}
                />
                <PeopleSearch
                    value={filters.mit_id}
                    onChange={(item: any) => onChangeFilter(item, 'mit_id')} />
            </div>
            <div className={'mt-3'}>
                <Label
                    text={'UROP'}
                />
                <Checkbox
                    checked={filters.urop}
                    onChange={(item: any) => onChangeFilter(item, 'urop')} />
            </div>
            <div className='d-flex flex-row mt-4'>
                <Button onClick={handleSearch}>
                    Search
            </Button>
                <Button variant='link' className='ml-3' onClick={clearFilters}>
                    clear filters
            </Button>
            </div>

        </div>
    );
}