const appConfig = {
  "version": "1.3.1+106",
  "stage": "master",
  "amplify": {
    "region": "us-east-1",
    "userPoolId": "us-east-1_bCu6BU8d2",
    "userPoolWebClientId": "h0gct0o226i9vvlamsirjvlos",
    "oauth": {
      "domain": "atlas-auth.mit.edu",
      "scope": [
        "profile",
        "openid",
        "elo/user",
        "digital-id/search",
        "digital-id/user"
      ],
      "redirectSignIn": "https://elo-tracking.mit.edu/",
      "redirectSignOut": "https://elo-tracking.mit.edu/logout",
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  },
  "signInProvider": "Touchstone",
  "api": {
    "hostname": "https://api.mit.edu",
    "paths": {
      "elo": "elo-tracking-v1",
      "digital-id": "digital-id-v1"
    }
  }
};
export default appConfig;