import React from 'react'

import Select from 'react-select';

interface Props {
    value: any
    options: any
    placeholder: string
    onChange: (item: any) => void
    isMulti?: boolean
}

export const Dropdown: React.FC<Props> = ({ value, options, placeholder, onChange, isMulti }) => {
    return (
        <Select
            isMulti={isMulti}
            options={options}
            isLoading={options.length === 0}
            blurInputOnSelect={false}
            placeholder={placeholder}
            onChange={(item: any, options: any) => onChange(item)}
            value={value ? value : null}
            className="people-search"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id} />
    );
}