import React, { useEffect, useState } from 'react'

import GuaranteeController from '../api/controller/GuaranteeController'
import { GuaranteeBodyType } from '../types';
import { BasicLayout, AdvancedTable, IAdvancedTableColumn, IAdvancedTableExportCsvColumn, ButtonType, ButtonState } from '@mit/hui';

import { formatName } from '../common/Util'
const controller = new GuaranteeController();



const columns: IAdvancedTableColumn[] = [
    {
        id: 'student_name',
        name: 'Name',
        formatter: (item: any) => formatName(item.student_name)
    },
    {
        id: 'mit_id',
        name: 'MIT ID'
    },
    {
        id: 'student_email',
        name: 'Email'
    },
    {
        id: 'year',
        name: 'Year'
    },
    {
        id: 'class',
        name: 'Class'
    },
    {
        id: 'advisor_name',
        name: 'Advisor Name',
        formatter: (item: any) => formatName(item.advisor_name)
    },
    {
        id: 'advisor_email',
        name: 'Advisor Email'
    }
]

const csvExportCol: IAdvancedTableExportCsvColumn[] = [
    {
        id: 'student_name',
        name: 'Name',
        formatter: (item: any) => formatName(item.student_name)
    },
    {
        id: 'mit_id',
        name: 'MIT ID'
    },
    {
        id: 'student_email',
        name: 'Email'
    },
    {
        id: 'year',
        name: 'Year'
    },
    {
        id: 'class',
        name: 'Class'
    },
    {
        id: 'course',
        name: 'Course'
    },
    {
        id: 'double_major',
        name: 'Double Major'
    },
    {
        id: 'citizen',
        name: 'Citizen'
    },
    {
        id: 'gender',
        name: 'Gender'
    },
    {
        id: 'ethnicity',
        name: 'Ethnicity'
    },
    {
        id: 'first_gen',
        name: 'First Gen'
    },

    {
        id: 'advisor_name',
        name: 'Advisor Name',
        formatter: (item: any) => formatName(item.advisor_name)
    },
    {
        id: 'advisor_email',
        name: 'Advisor Email'
    }
]

const exportOptions = {
    button: {
        type: ButtonType.Primary,
        icon: 'download',
        onClick: () => alert('Download Csv'),
        text: 'Export CSV',
        state: ButtonState.Enabled
    },
    columns: csvExportCol,
    items: [],
    fileName: `Students Without Guarantee`,
    customHeader: ',,,,,,,,,,,,,,,,,,,,,,SCHEDULE,,,,,,,,,,,,,\n,,,,,,,,,,,,,,,,,,,,,,Monday,,Tuesday,,Wednesday,,Thursday,,Friday,,Saturday,,Sunday,\n'

};



export const NonGuarantee: React.FC = () => {
    const [items, setItems] = useState<GuaranteeBodyType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true)
        controller.getStudentsWithoutGuarantees()
            .then(data => {
                setItems(data);
                setIsLoading(false)
            })

    }, [])

    return (
        <BasicLayout
            contentTitle='Students Without Guarantee'
            fullWidth
            startState={true}
            content={
                <div className='unallocated-table'>
                    <AdvancedTable
                        columns={columns}
                        items={items}
                        isLoading={isLoading}
                        exportOptions={exportOptions}
                        showExportButton
                        maxRows={500}
                        numberRowsOnScroll={100}

                    />
                </div>
            }
        />
    );
}