import BaseController from './BaseController';

import { GuaranteeBodyType } from '../../types/Guarantee';

export default class GuaranteeController extends BaseController {

    async getDepartments(): Promise<any> {

        let response = await this.useFetch('GET', 'guarantee/departments', `${this.apiHost}/${this.apiPath}/departments`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

    async getGuarantees(dlc_key: string): Promise<GuaranteeBodyType> {

        let response = await this.useFetch('GET', 'get/guarantee', `${this.apiHost}/${this.apiPath}/guarantees?dlc_key=${encodeURIComponent(dlc_key)}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

    async postGuarantees(body: GuaranteeBodyType): Promise<any> {

        let response = await this.useFetch('POST', 'post/guarantee', `${this.apiHost}/${this.apiPath}/guarantees`, body);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

    async putGuarantees(body: GuaranteeBodyType): Promise<any> {

        let response = await this.useFetch('PUT', 'post/guarantee', `${this.apiHost}/${this.apiPath}/guarantees/${body.id}`, body);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

    async deleteGuarantees(id: string): Promise<any> {

        let response = await this.useFetch('DELETE', 'delete/guarantee', `${this.apiHost}/${this.apiPath}/guarantees/${id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

    async getFilterGuarantees(queryString: any): Promise<GuaranteeBodyType> {


        let response = await this.useFetch('GET', 'get/filterGuarantee', `${this.apiHost}/${this.apiPath}/guarantees${queryString}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

    async getS3Url(fileName: any, dlc_key: any): Promise<any> {

        let response = await this.useFetch('GET', 'get/upload_url', `${this.apiHost}/${this.apiPath}/guarantees/upload?file_name=${fileName}&dlc_key=${encodeURIComponent(dlc_key)}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async uploadAccounts(url: string, file: any, fileName: any): Promise<any> {

        let response = await this.useFetch('PUT', 'vcs/upload', url, file, true, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        if (response.status === 200) {
            return true;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async getStudentsWithoutGuarantees(): Promise<GuaranteeBodyType[]> {

        let response = await this.useFetch('GET', 'get/guarantee/unallocated', `${this.apiHost}/${this.apiPath}/guarantees/unallocated`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = await response.json();
            return Promise.reject(data.message);
        }
    }

}