import React from 'react'

import { Button } from '@mit/hui'
import { useFormContext } from '../../context'
import { GuaranteeObject } from '../../api/model/Guarantee'
import { ActionType } from '../../types'
import cloneDeep from 'clone-deep';

interface Props {
    placeholder?: any
}

export const ShowFormButtons: React.FC<Props> = ({ placeholder }) => {
    const { setGuaranteeForm, setGuaranteeUploadForm }: any = useFormContext();

    const handelClick = () => {
        setGuaranteeForm({
            show: true,
            type: ActionType.CREATE,
            data: cloneDeep(GuaranteeObject)
        })
    }

    const handleClickUpload = () => {
        setGuaranteeUploadForm({
            show: true,
            data: {}
        })
    }

    return (
        <div className='form-buttons-container'>
            <Button
                text='Enter ELO Guarantees'
                onClick={handelClick}
            />
            <Button
                text='Upload ELO Guarantees'
                onClick={handleClickUpload}
            />
        </div>
    );
}