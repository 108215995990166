import React from 'react';
import { Alert } from 'react-bootstrap';

interface Props {
    banners: any;
    setBanners: any;
}

export const Banner: React.FC<Props> = ({ banners, setBanners }) => {

    if (!banners || !banners.id)
        return <span></span>

    return (
        < Alert variant={banners.type} onClose={() => setBanners({ id: null })} dismissible className='mb-3' >
            <Alert.Heading>{banners.header}</Alert.Heading>
            <p>
                {banners.text}
            </p>
            {
                banners.messages.map((item: any, index: number) => (
                    <Alert key={index} variant={'danger'}>
                        {`Row ${item.rowNumber}: ${item.errorMessage}`}
                    </Alert>
                ))
            }
        </Alert >
    );
}