import React from 'react'

import { Row, Col } from 'react-bootstrap';
import { Label } from '@mit/hui';

import { ActionType } from '../../types/Table';



interface Props {
    label: string
    inputComponent: any
    type: ActionType | string
    subLabel?: string
    value?: any
    required?: boolean
    error?: boolean
    fullWidth?: boolean
}

export const FormRow: React.FC<Props> = ({ label, inputComponent, required, error, value, type, subLabel, fullWidth }) => {
    return (
        <Row xs='1' xl='2' className='mt-3'>
            <Col xl={fullWidth ? 12 : 4}>
                <Label
                    text={label}
                />
                <p className='sub-label'>{subLabel}</p>
            </Col>
            <Col xl={fullWidth ? 12 : 8}>
                {(type === ActionType.CREATE || type === ActionType.EDIT) ?
                    <div className={'form-row-component-container'}>
                        {inputComponent}
                        {required && !value && <span className="text-required-dot-1"></span>}
                        {error && <div className="shake-horizontal required-validation">Required</div>}
                    </div> :
                    <h6>{value && value.hasOwnProperty('name') ? value.name : value}</h6>


                }

            </Col>
        </Row>
    );
}