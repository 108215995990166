import React from 'react'

import { GuaranteeTable } from '../table/GuaranteeTable';


interface Props {
    context: any
}

export const FilterDetail: React.FC<Props> = ({ context }) => {


    return <GuaranteeTable
        context={context}

    />


}