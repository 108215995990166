import React, { useState } from 'react'
// import { useParams } from 'react-router-dom'

import { Master } from './Master';
import { Detail } from './Detail';
// import { GuaranteeForm } from '../form/GuaranteeForm';
import { useGuaranteesContext } from '../../context/';
import { ShowFormButtons } from '../form';

import { TemplateMasterDetail } from '@mit/hui';

interface Props {
    placeholder?: any
}

export const MasterDetail: React.FC<Props> = () => {
    const [view, setView] = useState<number>(1);
    const context = useGuaranteesContext();
    const { selectedItem }: any = context;

    return (
        <>
            <TemplateMasterDetail
                // key={masterItems.length}
                master={<Master context={context} />}
                masterTitle={'Master'}
                detail={<Detail context={context} />}
                detailTitle={selectedItem.name}
                navigation={<ShowFormButtons />}
                onBack={() => setView(1)}
                mobileView={view}
                startState={true}
                fullWidth={true}
                compact={true}
            // utilNavigation={
            //     {
            //         type: 1,
            //         buttons: [{
            //             icon: '',
            //             text: 'Enter ELO',
            //             onClick: () => console.log('button clicked'),
            //             type: 1,
            //             state: 0
            //         }]
            //     }
            // }

            />
            {/* <GuaranteeForm
                item={row}
                show={showForm}
                type={formType}
                onClose={() => setShowForm(false)}
            /> */}
        </>
    );
}