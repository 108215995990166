import React from 'react'

import { GuaranteeForm, GuaranteeUploadForm } from '../form';
import { useFormContext } from '../../context'

interface Props {
    placeholder?: any
}

export const Forms: React.FC<Props> = ({ placeholder }) => {
    const { guaranteeForm, setGuaranteeForm, guaranteeUploadForm, setGuaranteeUploadForm }: any = useFormContext();

    return (
        <>
            <GuaranteeForm
                item={guaranteeForm.data}
                show={guaranteeForm.show}
                type={guaranteeForm.type}
                onClose={() => setGuaranteeForm({ show: false, data: {} })}
            />

            <GuaranteeUploadForm
                show={guaranteeUploadForm.show}
                onClose={() => setGuaranteeUploadForm({ show: false, data: {} })}
            />
        </>
    );
}