import React from 'react'

import { NavBar, NavBarTypes, NavItemState } from '@mit/hui';
import { useAppContext } from '../../context'

interface Props {
    placeholder?: any
}


export const MainNavBar: React.FC<Props> = () => {
    const { auth }: any = useAppContext();

    let tabs: any = [
        {
            icon: 'users',
            iconOnly: false,
            text: "Guarantee",
            to: '/',
            state: window.location.pathname === '/' ? NavItemState.Active : NavItemState.None
        },
        {
            icon: 'search',
            iconOnly: false,
            text: "Search",
            to: '/search',
            state: window.location.pathname === '/search' ? NavItemState.Active : NavItemState.None
        }
    ];

    if (auth.manage) {
        tabs.push({
            icon: 'list-ul',
            iconOnly: false,
            text: "Students Without Guarantee",
            to: '/non-guarantee',
            state: window.location.pathname === '/non-guarantee' ? NavItemState.Active : NavItemState.None
        })
    }

    return (
        <NavBar
            type={NavBarTypes.IconText}
            tabs={tabs}
        />
    );
}