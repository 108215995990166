import React, { useState, useEffect } from 'react'

import { Drawer, DrawerType } from '@mit/hui';
import { Row, Col, Button, Alert, Form } from 'react-bootstrap';

import { PeopleSearch } from '../input/PeopleSearch';
import { Dropdown } from '../input/Dropdown';
import Loading from '../Loading';
import { GuaranteeStudentDetails } from './GuaranteeStudentDetails'

import { ActionType } from '../../types/Table';
import { FormRow } from './FormRow';
import GuaranteeController from '../../api/controller/GuaranteeController';

import { useLookupsContext, useGuaranteesContext, useAppContext } from '../../context/'
import { Checkbox } from '../input/Checkbox';


const controller = new GuaranteeController();

interface Props {
    item: any
    show: boolean,
    type: ActionType,
    onClose: () => void

}

export const GuaranteeForm: React.FC<Props> = ({ item, type, show, onClose }) => {
    const [state, setState] = useState<any>({})
    const [error, setError] = useState<any>({});
    const [banners, setBanners]: any = useState({ id: null, messages: [] });
    const [isLoading, setIsloading] = useState<boolean>(false);
    const { departments, paymentTypes, terms, location, funding }: any = useLookupsContext();
    const { setRefresh }: any = useGuaranteesContext();
    const { auth }: any = useAppContext();

    const getTitle = () => {
        switch (type) {
            case ActionType.VIEW: return 'View ELO Guarantee';
            case ActionType.CREATE: return 'Enter ELO Guarantee';
            case ActionType.DELETE: return 'Delete ELO Guarantee';
            case ActionType.EDIT: return 'Edit ELO Guarantee';
        }
    }

    const getSubmitButtonText = () => {
        switch (type) {
            case ActionType.CREATE: return 'Submit';
            case ActionType.DELETE: return 'Delete';
            case ActionType.EDIT: return 'Update';
        }
    }

    const scrollTop = () => {
        let drawer: any = document.getElementsByClassName("drawer-body")[0];

        if (drawer) {
            const parent = drawer.parentNode;
            parent.scrollTop = 0;
        }

    }

    const resetForm = () => {
        setError({})
        setBanners({ id: null });
        onClose();
    }

    const validateForm = () => {
        let valid = true,
            errors: any = {};

        if (!state.student)
            errors.student = true;
        if (!state.sponsor)
            errors.sponsor = true;
        if (!state.program_name)
            errors.program_name = true;
        // if (!state.sponsor_name)
        //     errors.sponsor_name = true;
        if (!state.department)
            errors.department = true;
        if (!state.term)
            errors.term = true;
        if (!state.location)
            errors.location = true;
        // if (!state.project_title)
        //     errors.project_title = true;
        if (!state.max_awarded_amount)
            errors.max_awarded_amount = true;
        if (!state.payment)
            errors.student = true;
        if (!state.funding)
            errors.funding = true;
        if (state.funding && state.funding.id === 1 && (!state.cost_object && auth.config['COST_OBJECT_REQUIRED']))
            errors.cost_object = true;

        setError(errors);
        if (Object.keys(errors).length > 0)
            valid = false;

        return valid;
    }

    const onSubmit = (event: any) => {
        event.preventDefault();

        let valid = validateForm() || type === ActionType.DELETE;

        if (valid && !isLoading) {
            console.log(state);
            setIsloading(true);
            setBanners({ id: null });
            if (type === ActionType.CREATE) {
                controller.postGuarantees(state)
                    .then(data => {
                        setIsloading(false);
                        setRefresh((state: number) => state + 1)
                        setBanners({ id: 1, header: 'Success', text: `Guarantee was successfully created`, type: 'success', messages: [] })
                        scrollTop();
                    })
                    .catch(error => {
                        console.error(error);
                        setIsloading(false);
                        setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                        scrollTop();
                    })
            } else if (type === ActionType.EDIT) {
                controller.putGuarantees(state)
                    .then(data => {
                        setIsloading(false);
                        setRefresh((state: number) => state + 1)
                        setBanners({ id: 1, header: 'Success', text: `Guarantee was successfully updated`, type: 'success', messages: [] })
                        scrollTop();
                    })
                    .catch(error => {
                        console.error(error);
                        setIsloading(false);
                        setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                        scrollTop();
                    })
            } else if (type === ActionType.DELETE) {
                controller.deleteGuarantees(state.id)
                    .then(data => {
                        setIsloading(false);
                        setRefresh((state: number) => state + 1)
                        setBanners({ id: 1, header: 'Success', text: `Guarantee was successfully deleted`, type: 'success', messages: [] })
                        scrollTop();
                    })
                    .catch(error => {
                        console.error(error);
                        setIsloading(false);
                        setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                        scrollTop();
                    })
            }
        }
    }

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        event.persist();
        console.log(event);
        setState((state: any) => ({ ...state, [key]: event.target ? event.target.value : '' }))
    }

    const onChangeCostObject = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        let sValue = event.target.value.slice(0, 7);
        let value: number = parseInt(sValue);

        setState((state: any) => ({ ...state, cost_object: value }))
    }

    const onChangeFunding = (item: any) => {
        // debugger;
        let amount = state.max_awarded_amount;
        if (item && item.id === 1) {
            amount = getAmount(amount, item);
        }

        setState((state: any) => ({ ...state, funding: item, max_awarded_amount: amount }))
    }

    const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        let value = getAmount(event.target.value, state.funding);

        setState((state: any) => ({ ...state, max_awarded_amount: value }))
    }

    const getAmount = (amount: string, funding: any) => {
        let max_amount = parseInt(auth.config['MAX_AWARDED_AMOUNT']),
            min_amount = 0;

        let value: number = parseInt(amount ? amount : '');

        if (value > max_amount && funding && funding.id === 1)
            value = 1900;

        if (value < min_amount)
            value = min_amount;

        return value;
    }

    useEffect(() => {
        setState(item);
    }, [item])



    return (
        <Drawer
            type={DrawerType.Bottom}
            show={show}
            header={<h5>{getTitle()}</h5>}
            footer={[]}
            onClose={resetForm}
            contents={
                <div id='guarantee-form-content'>
                    <Loading loaded={isLoading} />
                    {banners['id'] &&
                        <Alert variant={banners.type} onClose={() => setBanners({ id: null })} dismissible className='mb-3'>
                            <Alert.Heading>{banners.header}</Alert.Heading>
                            <p>
                                {banners.text}
                            </p>
                            {banners.messages.map((item: any, index: number) => (
                                <Alert key={index} variant={'danger'}>
                                    {`Row ${item.rowNumber}: ${item.errorMessage}`}
                                </Alert>
                            ))}
                        </Alert>}
                    <form onSubmit={onSubmit}>
                        <Row xs='1' md='2'>
                            <Col md='6' >
                                <FormRow
                                    label='Student'
                                    inputComponent={
                                        <PeopleSearch value={state.student} onChange={(value: any) => setState((state: any) => ({ ...state, student: value }))} />
                                    }
                                    required={true}
                                    value={state.student}
                                    error={error.student}
                                    type={type === ActionType.CREATE ? type : ActionType.VIEW}
                                />
                                <FormRow
                                    label='Supervisor'
                                    inputComponent={
                                        <PeopleSearch value={state.sponsor} onChange={(value: any) => setState((state: any) => ({ ...state, sponsor: value }))} />
                                    }
                                    required={true}
                                    value={state.sponsor}
                                    error={error.sponsor}
                                    type={type}
                                />

                                <FormRow
                                    label='Department'
                                    inputComponent={
                                        <Dropdown
                                            value={state.department}
                                            options={departments}
                                            placeholder='Select a Department'
                                            onChange={(value: any) => setState((state: any) => ({ ...state, department: value }))}
                                        />
                                    }
                                    required={true}
                                    value={state.department}
                                    error={error.department}
                                    type={type}
                                />
                                <FormRow
                                    label='Term'
                                    inputComponent={
                                        <Dropdown
                                            value={state.term}
                                            options={terms}
                                            placeholder='Select a Term'
                                            onChange={(value: any) => setState((state: any) => ({ ...state, term: value }))}
                                        />
                                    }
                                    required={true}
                                    value={state.term}
                                    error={error.term}
                                    type={type}
                                />
                                <FormRow
                                    label='Student Location'
                                    inputComponent={
                                        <Dropdown
                                            value={state.location}
                                            options={location}
                                            placeholder='Select a Location'
                                            onChange={(value: any) => setState((state: any) => ({ ...state, location: value }))}
                                        />
                                    }
                                    required={true}
                                    value={state.location}
                                    error={error.location}
                                    type={type}
                                />
                                <FormRow
                                    label='Payment Mode'
                                    inputComponent={
                                        <Dropdown
                                            value={state.payment}
                                            options={paymentTypes}
                                            placeholder='Select a Payment Type'
                                            onChange={(value: any) => setState((state: any) => ({ ...state, payment: value }))}
                                        />
                                    }
                                    required={true}
                                    value={state.payment}
                                    error={error.payment}
                                    type={type}
                                />
                                <FormRow
                                    label='Source of funding'
                                    inputComponent={
                                        <Dropdown
                                            value={state.funding}
                                            options={funding}
                                            placeholder='Select a Funding Type'
                                            onChange={onChangeFunding}
                                        />
                                    }
                                    required={true}
                                    value={state.funding}
                                    error={error.funding}
                                    type={type}
                                />
                                {(state.funding && state.funding.id === 1) &&
                                    <FormRow
                                        label='Cost Object'
                                        inputComponent={
                                            <input
                                                value={state.cost_object}
                                                type={'number'}
                                                onChange={(e: any) => onChangeCostObject(e)}
                                                className='input'
                                                placeholder='Enter Cost Object'
                                            />
                                        }
                                        required={auth.config['COST_OBJECT_REQUIRED'] ? true : false}
                                        value={state.cost_object}
                                        error={error.cost_object}
                                        type={type}
                                    />
                                }
                                {/* <FormRow
                                    label='Project Title'
                                    inputComponent={
                                        <input
                                            value={state.project_title}
                                            type={'text'}
                                            onChange={(e: any) => onChangeInput(e, 'project_title')}
                                            className='input'
                                            placeholder='Project TItle'
                                        />
                                    }
                                    required={true}
                                    value={state.project_title}
                                    error={error.project_title}
                                    type={type}
                                /> */}

                                <FormRow
                                    label='Maximum Amount Awarded to Student'
                                    subLabel={`(max $${auth.config['MAX_AWARDED_AMOUNT']})`}
                                    inputComponent={
                                        <input
                                            value={state.max_awarded_amount}
                                            type={'number'}
                                            onChange={(e: any) => onChangeAmount(e)}
                                            className='input'
                                            placeholder='Enter Maximum Amount Awarded'
                                        />
                                    }
                                    required={true}
                                    value={state.max_awarded_amount}
                                    error={error.max_awarded_amount}
                                    type={type}
                                />
                                <FormRow
                                    label='ELO Program'
                                    inputComponent={
                                        <input
                                            value={state.program_name}
                                            type={'text'}
                                            onChange={(e: any) => onChangeInput(e, 'program_name')}
                                            className='input'
                                            placeholder='Enter Program'
                                        />
                                    }
                                    required={true}
                                    value={state.program_name}
                                    error={error.program_name}
                                    type={type}
                                />
                                <FormRow
                                    label='UROP'
                                    inputComponent={
                                        <Checkbox
                                            checked={state.urop}
                                            onChange={(item: any) => setState((state: any) => ({ ...state, urop: item }))} />
                                    }
                                    required={false}
                                    value={state.urop}
                                    error={error.urop}
                                    type={type}
                                />
                                {/* <FormRow
                                    label='Sponsor Name'
                                    inputComponent={
                                        <input
                                            value={state.sponsor_name}
                                            type={'text'}
                                            onChange={(e: any) => onChangeInput(e, 'sponsor_name')}
                                            className='input'
                                            placeholder='Enter Sponsor Name'
                                        />
                                    }
                                    required={false}
                                    value={state.sponsor_name}
                                    error={error.sponsor_name}
                                    type={type}
                                /> */}

                                <FormRow
                                    label='Comments'
                                    inputComponent={
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={state.comments}
                                            onChange={(e: any) => onChangeInput(e, 'comments')} />
                                    }
                                    required={false}
                                    value={state.comments}
                                    error={error.comments}
                                    type={type}
                                />



                            </Col>
                            <Col md='4' className='ml-5' >
                                {(type !== ActionType.CREATE && auth.manage) &&
                                    <GuaranteeStudentDetails
                                        state={state}
                                    />}
                            </Col>
                        </Row>
                        {/* <button type="submit">Submit</button> */}
                        {type !== ActionType.VIEW &&
                            <Button
                                type='submit'
                                variant={'primary'}
                                className='mt-3'
                                disabled={isLoading}
                            >{getSubmitButtonText()}</Button>}
                    </form>
                </div>
                // <Form formItems={formItemData1} actionBar={actionBarData} formValidationType={1} />
            }



        />
    );
}