import React, { useState, createContext } from 'react';

import { GuaranteesProvider } from './GuaranteesContext'
import { LookupsProvider } from './LookupsContext'
import { StudentProvider } from './StudentContext';
import { FilterProvider } from './FilterContext';
import { FormProvider } from './FormContext';



export const AppContext = createContext({});

interface Props {
    children: React.ReactNode
}


export const AppProvider: React.FC<Props> = ({ children }) => {
    const [auth, setAuth]: any = useState({
        view: false,
        manage: false
    });
    const [profile, setProfile]: any = useState({});
    const [profilePicture, setProfilePicture]: any = useState(null);
    const [isLoading, setIsLoading]: any = useState({
        auth: true
    });
    const [refresh, setRefresh] = useState<number>(0);


    const exportValue = {
        auth,
        setAuth,
        profile,
        setProfile,
        profilePicture,
        setProfilePicture,
        isLoading,
        setIsLoading,
        refresh,
        setRefresh
    }

    // const PiProvider = TypesContext[0].provider;
    // const PiContext = TypesContext[0].context;


    return (
        <AppContext.Provider value={exportValue} >
            <LookupsProvider>
                <GuaranteesProvider>
                    <StudentProvider>
                        <FilterProvider>
                            <FormProvider>
                                {children}
                            </FormProvider>
                        </FilterProvider>
                    </StudentProvider>
                </GuaranteesProvider>
            </LookupsProvider>
        </AppContext.Provider >
    )
}

export const useAppContext = () => {
    const context = React.useContext(AppContext)
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppProvider')
    }
    return context
}






