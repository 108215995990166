import React, { useState, createContext } from 'react';

import { ListItem } from '../types/Lookup';

export const StudentContext = createContext({

});

interface Props {
    children: React.ReactNode
}


export const StudentProvider: React.FC<Props> = ({ children }) => {
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>({ list: true, items: true });
    const [firstLoad, setFirstLoad] = useState<Boolean>(true);
    const [refresh, setRefresh] = useState<number>(0);

    const exportValue = {
        listItems,
        setListItems,
        items,
        setItems,
        selectedItem,
        setSelectedItem,
        isLoading,
        setIsLoading,
        firstLoad,
        setFirstLoad,
        refresh,
        setRefresh,
    }

    return (
        <StudentContext.Provider value={exportValue}>
            {children}
        </StudentContext.Provider>
    )

}


export const useStudentContext = () => {
    const context = React.useContext(StudentContext)
    if (context === undefined) {
        throw new Error('useStudentContext must be used within a AppProvider')
    }
    return context
}



