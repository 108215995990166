import BaseController from './BaseController';

export default class LookupsController extends BaseController {

    async getPaymentTypes(): Promise<any> {

        let response = await this.useFetch('GET', 'lookups/payment_types', `${this.apiHost}/${this.apiPath}/lookups/payment_types`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = response.json();
            return Promise.reject(data.message);
        }
    }

    async getTerms(): Promise<any> {

        let response = await this.useFetch('GET', 'lookups/terms', `${this.apiHost}/${this.apiPath}/lookups/terms`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = response.json();
            return Promise.reject(data.message);
        }
    }

    async getDepartments(): Promise<any> {

        let response = await this.useFetch('GET', 'lookups/departments', `${this.apiHost}/${this.apiPath}/lookups/departments`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = response.json();
            return Promise.reject(data.message);
        }
    }

    async getLocations(): Promise<any> {

        let response = await this.useFetch('GET', 'lookups/locations', `${this.apiHost}/${this.apiPath}/lookups/locations`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = response.json();
            return Promise.reject(data.message);
        }
    }

    async getFundingSources(): Promise<any> {

        let response = await this.useFetch('GET', 'lookups/funding_sources', `${this.apiHost}/${this.apiPath}/lookups/funding_sources`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = response.json();
            return Promise.reject(data.message);
        }
    }
}