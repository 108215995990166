import React, { useEffect } from 'react'

import { useAppContext } from '../../context/';
import AuthProvider from '../../api/AuthProvider';
import UserController from '../../api/controller/UserController';

import { Profile, DropdownItemProps, DropDownState } from '@mit/hui';

const userController = new UserController();

const authProvider = new AuthProvider();
interface Props {
    placeholder?: any
}

export const LoggedInUser: React.FC<Props> = () => {
    const { profile, profilePicture, setProfile, setProfilePicture }: any = useAppContext();

    const subMenuData: DropdownItemProps[] = [
        {
            icon: 'sign-out', text: 'Logout', state: DropDownState.None, onClick: () => {
                authProvider.logout().then((data) => { });
            }
        },
    ];

    useEffect(() => {
        if (!profile.display_name) {
            userController.getProfile()
                .then(data => {
                    setProfile(data);
                })
        }
        if (!profilePicture) {
            userController.getProfilePicture()
                .then(data => {
                    let url = URL.createObjectURL(data);
                    setProfilePicture(url);
                })
        }
        //eslint-disable-next-line
    }, [])

    return (
        <Profile name={profile.display_name} subtitle={profile.department} imageUrl={profilePicture} submenu={subMenuData} />
    );
}