import React from 'react'

import { Row, Col } from 'react-bootstrap';
import { Label } from '@mit/hui';
import { getDateTimeString } from '../../common/Util';
interface Props {
    state: any
}

export const GuaranteeStudentDetails: React.FC<Props> = ({ state }) => {
    return (
        <>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Year'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.year}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Class'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.class}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Course'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.course}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Double Major'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.double_major === 'Z' ? 'Yes' : 'No'}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Citizen'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.Citizen}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Gender'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.gender}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Ethnicity'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.ethnicity}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'First Gen'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.first_gen}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Advisor Name'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.advisor_name}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Advisor Email'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.advisor_email}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Created On'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{getDateTimeString(state.created_on, true)}</h6>
                </Col>
            </Row>
            <Row xs='1' xl='2' className='mt-3'>
                <Col xl='4'>
                    <Label
                        text={'Created By'}
                    />
                </Col>
                <Col xl='8'>
                    <h6>{state.created_by}</h6>
                </Col>
            </Row>
            {!!state.updated_on &&
                <>
                    <Row xs='1' xl='2' className='mt-3'>
                        <Col xl='4'>
                            <Label
                                text={'Updated On'}
                            />
                        </Col>
                        <Col xl='8'>
                            <h6>{getDateTimeString(state.updated_on, true)}</h6>
                        </Col>
                    </Row>
                    <Row xs='1' xl='2' className='mt-3'>
                        <Col xl='4'>
                            <Label
                                text={'Updated By'}
                            />
                        </Col>
                        <Col xl='8'>
                            <h6>{state.updated_by}</h6>
                        </Col>
                    </Row>
                </>
            }
        </>

    );
}