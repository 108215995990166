import BaseController from './BaseController';

export default class PeopleSearchController extends BaseController {

    async getPeople(searchTerm: string): Promise<any> {

        let response = await this.useFetch('GET', 'lookups/mit-person', `${this.apiHost}/${this.apiPathDigitalId}/search?q=${searchTerm}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }

    }
}