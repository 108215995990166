import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppProvider } from './context/';
// import 'bootstrap/dist/js/bootstrap.js';
// import 'bootstrap/dist/css';
import './scss/main.scss';


ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


