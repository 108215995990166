export interface ColumnHeaders {
    id: string
    name: string
    formatter?: (item: any) => string | number | null
}

export enum ActionType {
    VIEW,
    EDIT,
    DELETE,
    CREATE
}

export interface ActionButton {
    tooltip: string
    icon: string
    actionType: ActionType
    onClick: (type: ActionType, item: any) => void
}