import React, { useEffect } from 'react';
import './scss/main.scss';

import AuthProvider from './api/AuthProvider';
import BaseController from './api/controller/BaseController';
import LookupsController from './api/controller/LookupsController';

import { useLookupsContext, useAppContext } from './context/';
import { Home, Forms } from './components/container';
import { BrowserRouter as Router } from 'react-router-dom';
import { Loader } from '@mit/hui';

const baseController = new BaseController();
const lookupsController = new LookupsController();
const authProvider = new AuthProvider();

const App: React.FC = () => {
  const { setDepartments, setPaymentTypes, setTerms, setLocation, setFunding }: any = useLookupsContext();
  const { auth, setAuth, isLoading, setIsLoading }: any = useAppContext();

  useEffect(() => {
    authProvider.getToken()
      .then(data => {
        console.log(data);

        baseController.getUserAuth()
          .then(data => {
            setAuth(data);
            setIsLoading((state: any) => ({ ...state, auth: false }))
          })

        //get lookups
        lookupsController.getPaymentTypes()
          .then(data => {
            setPaymentTypes(data);
          })
        lookupsController.getTerms()
          .then(data => {
            setTerms(data);
          })
        lookupsController.getDepartments()
          .then(data => {
            setDepartments(data);
          })
        lookupsController.getLocations()
          .then(data => {
            setLocation(data);
          })
        lookupsController.getFundingSources()
          .then(data => {
            setFunding(data);
          })
      })
    //eslint-disable-next-line
  }, [])

  console.log(auth);

  if (isLoading.auth || (!auth.view && !auth.manage)) {
    return (
      <Loader
        contactEmail='help@mit.edu'
        hasAccess={(!auth.view && !auth.manage)}
        name='ELO Tracking'
      />
    )
  }

  return (
    <Router>
      <>
        <Home />
        <Forms />
      </>
    </Router>
  );
}

export default App;
