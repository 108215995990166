import BaseController from './BaseController';

export default class LookupsController extends BaseController {

    async getProfile(): Promise<any> {

        let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async getProfilePicture(): Promise<any> {

        let response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`);

        if (response.status === 200) {
            return response.blob();
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }
}