import React, { useState, createContext } from 'react';

import { Lookup } from '../types/Lookup';

export const LookupsContext = createContext({

});

interface Props {
    children: React.ReactNode
}


export const LookupsProvider: React.FC<Props> = ({ children }) => {
    const [departments, setDepartments] = useState<Lookup[]>([]);
    const [paymentTypes, setPaymentTypes] = useState<Lookup[]>([]);
    const [terms, setTerms] = useState<Lookup[]>([]);
    const [location, setLocation] = useState<Lookup[]>([]);
    const [funding, setFunding] = useState<Lookup[]>([]);

    const exportValue = {
        departments,
        setDepartments,
        paymentTypes,
        setPaymentTypes,
        terms,
        setTerms,
        location,
        setLocation,
        funding,
        setFunding
    }

    return (
        <LookupsContext.Provider value={exportValue}>
            {children}
        </LookupsContext.Provider>
    )

}

export const useLookupsContext = () => {
    const context = React.useContext(LookupsContext)
    if (context === undefined) {
        throw new Error('useLookupsContext must be used within a AppProvider')
    }
    return context
}


