import BaseController from './BaseController';

export default class StudentController extends BaseController {

    async getStudents(type: string): Promise<any> {

        let response = await this.useFetch('GET', 'students', `${this.apiHost}/${this.apiPath}/students?type=${type}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let data = response.json();
            return Promise.reject(data.message);
        }
    }

}