import React, { useState, createContext } from 'react';

export const FormContext = createContext({});

interface Props {
    children: React.ReactNode
}

export const initialForm = {
    show: false,
    data: {}
}


export const FormProvider: React.FC<Props> = ({ children }) => {
    const [guaranteeForm, setGuaranteeForm] = useState<object>({ ...initialForm });
    const [guaranteeUploadForm, setGuaranteeUploadForm] = useState<object>({ ...initialForm });


    const exportValue = {
        guaranteeForm,
        setGuaranteeForm,
        guaranteeUploadForm,
        setGuaranteeUploadForm
    }

    return (
        <FormContext.Provider value={exportValue}>
            {children}
        </FormContext.Provider>
    )

}

export const useFormContext = () => {
    const context = React.useContext(FormContext)
    if (context === undefined) {
        throw new Error('useFormContext must be used within a LookupsProvider')
    }
    return context
}

