import React, { useEffect } from 'react'

import GuaranteeController from '../../api/controller/GuaranteeController';

import { FilteredList, ListItemState, TextboxProps, TextboxType } from '@mit/hui';


const controller = new GuaranteeController();

interface Props {
    context: any
}

export const Master: React.FC<Props> = ({ context }) => {
    const { listItems, isLoading, selectedItem, setSelectedItem, setListItems, setIsLoading, refresh }: any = context;


    const searchOptions: TextboxProps = {
        name: 'Name',
        placeholderText: 'Filter List',
        startIcon: 'search',
        type: TextboxType.IconStart,

    }

    const items = listItems.map((item: any) => {
        return {
            id: item.id,
            text: item.name,
            state: item.id === selectedItem.id ? ListItemState.Active : ListItemState.None,
            onClick: () => setSelectedItem(item)
        }
    })


    useEffect(() => {

        controller.getDepartments()
            .then(data => {
                setListItems(data);
                setIsLoading((state: any) => ({ ...state, list: false }));
                if (data.length && !selectedItem.id)
                    setSelectedItem(data[0]);

            })
            .catch(error => {
                console.error(error);
                // setListItems([])
            })

        //eslint-disable-next-line
    }, [refresh])



    return (
        <FilteredList
            isLoading={isLoading.list}
            selectable={true}
            compact={true}
            searchOptions={searchOptions}
            items={items}
            height={60}
        />
    );
}