export const GuaranteeObject = {
    "id": "",
    "student": null,
    "supervisor": null,
    "department": null,
    "term": null,
    "location": null,
    "payment": null,
    "funding": null,
    "project_title": null,
    "program_name": null,
    "sponsor_name": null,
    "max_awarded_amount": null,
    "comments": null,
    "cost_object": null,
    "status": null,
    "urop": false,
    "created_on": "2020-08-27 15:52:03",
    "created_by": null,
    "updated_on": "2020-08-27 20:35:51",
    "updated_by": null
}