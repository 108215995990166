import React, { useState, useRef } from 'react';
import GuaranteeController from '../../api/controller/GuaranteeController';
import { Banner } from './Banner'
import { Dropdown } from '../input';
import { FormRow } from './';
import { useLookupsContext, useFormContext } from '../../context';
import { Drawer, DrawerType, DrawerSize, Button, ButtonType, ButtonState } from '@mit/hui';
import { Row, Col, Alert } from 'react-bootstrap';
import { ActionType } from '../../types';
import Loading from '../Loading';



const controller = new GuaranteeController();

interface Props {
    show: boolean,
    onClose: () => void
}

export const GuaranteeUploadForm: React.FC<Props> = ({ show, onClose }) => {
    const [department, setDepartment]: any = useState(null);
    const [errors, seterrors]: any = useState({});
    const [isLoading, setIsLoading]: any = useState<boolean>(false);
    const [banners, setBanners]: any = useState({ id: null, messages: [] });
    const [uploadFile, setUploadFile]: any = useState({ name: '', file: null });
    const fileUploadedInput: any = useRef(null);
    const { departments }: any = useLookupsContext();
    const { guaranteeUploadForm, setGuaranteeUploadForm }: any = useFormContext()


    const handleAllocationTypeChange = (value: any) => {
        setDepartment(value);
        seterrors((state: any) => ({ ...state, department: '' }));
    }

    const handleFileUpload = (event: any) => {
        if (event.target.files.length) {
            let file = event.target.files[0];
            setUploadFile((state: any) => ({ ...state, name: file.name, file: file }));
            seterrors((state: any) => ({ ...state, file: '' }));
        }
    }

    // const handleFileUpload = (file: any) => {
    //     if (file) {
    //         setUploadFile((state: any) => ({ ...state, name: file.name, file: file }));
    //         seterrors((state: any) => ({ ...state, file: '' }));
    //     }
    // }

    const onUploadFile = () => {
        setBanners({ id: null });
        setIsLoading(true);
        controller.getS3Url(uploadFile.name, department.id)
            .then(data => {
                return data.url
            })
            .then(url => {
                controller.uploadAccounts(url, uploadFile.file, uploadFile.name)
                    .then(data => {
                        setIsLoading(false);
                        setBanners({
                            id: 1, header: 'Success', text: 'File is being processed. You will receive an email when the upload has been completed.',
                            type: 'success', messages: []
                        });
                        if (fileUploadedInput.current) {
                            fileUploadedInput.current.value = '';
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setBanners({ id: 1, header: 'Error', text: 'There was a probem uploading the file', type: 'danger', messages: [] });
                    })
            })
            .catch(error => {
                setIsLoading(false);
                setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] });
            })

    }

    const validateUpload = () => {
        let errors: any = {};

        if (!uploadFile.name) {
            errors.file = 'Select a .xlsx file';
        }
        if (!department) {
            errors.department = true;

        }

        let hasErrors = Object.keys(errors).length > 0;
        seterrors(errors);
        if (!hasErrors) {
            onUploadFile();
        }
    }



    const clearForm = () => {
        setDepartment(null);
        seterrors({});
        setBanners({ id: null });
        setUploadFile({ name: '', file: null });
        if (fileUploadedInput.current) {
            fileUploadedInput.current.value = '';
        }

        setGuaranteeUploadForm({
            show: false,
            data: {}
        })

    }



    return (
        <Drawer
            type={DrawerType.Right}
            size={DrawerSize.Small}
            themedColor="medical"
            show={guaranteeUploadForm.show}
            header={<h3>Upload ELO Guarantees</h3>}
            footer={[]}
            onClose={clearForm}
            contents={
                <div className="form-upload">
                    <Loading loaded={isLoading} />
                    <Banner
                        banners={banners}
                        setBanners={setBanners}
                    />

                    <Row className='py-2 px-2'>
                        <Col>
                            <div className='upload-type-container'>
                                <FormRow
                                    inputComponent={
                                        <Dropdown
                                            options={departments}
                                            onChange={handleAllocationTypeChange}
                                            value={department}
                                            placeholder=''
                                        />
                                    }
                                    label='Department'
                                    type={ActionType.CREATE}
                                    error={errors.department}
                                    value={department}
                                    required
                                    fullWidth
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row xs='1' lg='1' className='mt-3'>
                        <Col>

                            <div className='file-upload-container'>
                                <div className='file-upload-template py-1'>
                                    <span><a aria-hidden="true" href="/ELO Guarantees Upload Template.xlsx" download>Download</a> the template file.</span>
                                </div>

                                <input
                                    accept=".xlsx"
                                    className={'input'}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleFileUpload}
                                    ref={fileUploadedInput}
                                />
                                <label htmlFor="contained-button-file">

                                    <div className='file-upload mt-3'>
                                        <div className='file-upload-content'>
                                            <i className="fas fa-cloud-upload mr-2"></i>
                                            <h5>Select .xlsx file to upload</h5>
                                            <h6 className='mt-2'>{uploadFile.name}</h6>
                                        </div>
                                    </div>
                                </label>

                                {/* <FileUpload
                                    acceptedMimeTypes={[".xlsx"]}
                                    onConfirm={handleFileUpload}
                                    isLoading={isLoading}
                                /> */}

                                {/* <h5 className='mt-2'>{uploadFile.name}</h5> */}
                                {errors.file && <Alert variant={'danger'}>{errors.file}</Alert>}
                                <Button
                                    text='Upload ELO Guarantees File'
                                    onClick={validateUpload}
                                    state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                    type={ButtonType.Primary}
                                />

                            </div>
                        </Col>
                    </Row>
                </div>
            }
        />

    );
}
