import React, { useState, createContext } from 'react';

import { ListItem } from '../types/Lookup';
import { ActionType } from '../types/Table';
import { GuaranteeBodyType } from '../types/Guarantee';

export const GuaranteesContext = createContext({

});

interface Props {
    children: React.ReactNode
}


export const GuaranteesProvider: React.FC<Props> = ({ children }) => {
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>({ list: true, items: false });
    const [firstLoad, setFirstLoad] = useState<Boolean>(true);
    const [refresh, setRefresh] = useState<number>(0);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [formType, setFormType] = useState<ActionType>(ActionType.VIEW);
    const [row, setRow] = useState<GuaranteeBodyType | {}>({})

    const exportValue = {
        listItems,
        setListItems,
        items,
        setItems,
        selectedItem,
        setSelectedItem,
        isLoading,
        setIsLoading,
        firstLoad,
        setFirstLoad,
        refresh,
        setRefresh,
        showForm,
        setShowForm,
        formType,
        setFormType,
        row,
        setRow
    }

    return (
        <GuaranteesContext.Provider value={exportValue}>
            {children}
        </GuaranteesContext.Provider>
    )

}

export const useGuaranteesContext = () => {
    const context = React.useContext(GuaranteesContext)
    if (context === undefined) {
        throw new Error('useGuaranteesContext must be used within a AppProvider')
    }
    return context
}


