import React, { useState, createContext } from 'react';

import { ListItem } from '../types/Lookup';

export const FilterContext = createContext({

});

interface Props {
    children: React.ReactNode
}


export const FilterProvider: React.FC<Props> = ({ children }) => {
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>({ list: false, items: false });
    const [firstLoad, setFirstLoad] = useState<Boolean>(true);
    const [refresh, setRefresh] = useState<number>(0);
    const [filters, setFilters] = useState<any>({});

    const exportValue = {
        listItems,
        setListItems,
        items,
        setItems,
        selectedItem,
        setSelectedItem,
        isLoading,
        setIsLoading,
        firstLoad,
        setFirstLoad,
        refresh,
        setRefresh,
        filters,
        setFilters
    }

    return (
        <FilterContext.Provider value={exportValue}>
            {children}
        </FilterContext.Provider>
    )

}

export const useFilterContext = () => {
    const context = React.useContext(FilterContext)
    if (context === undefined) {
        throw new Error('useFilterContext must be used within a AppProvider')
    }
    return context
}


