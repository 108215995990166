import React from 'react'

import { LoggedInUser } from '../nav/LoggedInUser';
import { MainNavBar } from '../nav/MainNavBar';
import { MasterDetail } from '../masterDetail/MasterDetail'
import { FilterMasterDetail } from '../filter/FilterMasterDetail';
// import { SearchMasterDetail } from '../search/SearchMasterDetail';
import { Student } from '../student/Student';
import appConfig from '../../app-config';

import { Switch, Route } from 'react-router-dom';
import { AtlasPage } from '@mit/hui';
import { NonGuarantee } from '../NonGuarantee';

interface Props {
    placeholder?: any
}

const getEnv = () => {
    switch (appConfig.stage) {
        case 'develop': return 'dev';
        case 'release': return 'staging';
        case 'master': return 'production';
    }
    return 'production';
}



export const Home: React.FC<Props> = () => {
    return (

        <AtlasPage
            gaId={''}
            theme='medical'
            environment={getEnv()}
            name={'ELO Tracking'}
            navigation={<MainNavBar />}
            profile={<LoggedInUser />}
            content={
                <>
                    <Switch>
                        <Route exact path={'/'} >
                            <MasterDetail />
                        </Route>
                        <Route path={'/students/:type?'} >
                            <Student />
                        </Route>
                        <Route path={'/search'} >
                            <FilterMasterDetail />
                        </Route>
                        <Route path={'/non-guarantee'} >
                            <NonGuarantee />
                        </Route>
                        {/* <Route path={'/search'} >
                            <SearchMasterDetail />
                        </Route> */}
                        {/* <Route path={'/sponsored-accounts'} component={SponsoredAccountMasterDetail} /> */}
                        {/* <Route
                            path="/"
                            render={() => <Redirect to="/allocation/1" />}
                            exact={true}
                        /> */}
                    </Switch>
                </>
            }
        />

    );
}